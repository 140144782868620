<template>
  <v-container xv-if='LOGGED_IN && false'>

    <v-row>

      <v-col cols="12" md="6" lg="5" offset-lg="1" class="">

        <v-card>
          <v-card-title class="primary white--text py-2 text-subtitle-2">New Jobs - Past 7 Days</v-card-title>
          <v-card-text class="pa-4">
            <v-sparkline
              :value="incoming" :labels="incoming_labels"
              :smooth="5" :line-width="2" padding="18" stroke-linecap="round"
              type="trend"
              auto-draw :auto-draw-duration="750"
              style="overflow:visible" class="px-1 sparklines"
            />
          </v-card-text>
        </v-card>

      </v-col>

      <v-col cols="12" md="6" lg="5" class="">

        <v-card>
          <v-card-title class="primary white--text py-2 text-subtitle-2">Due Jobs - Next 7 Days</v-card-title>
          <v-card-text class="pa-4">
            <v-sparkline
              :value="due" :labels="due_labels"
              :smooth="5" :line-width="2" padding="18" stroke-linecap="round"
              type="trend"
              auto-draw :auto-draw-duration="750"
              style="overflow:visible" class="px-1 sparklines"
            />
          </v-card-text>
        </v-card>

      </v-col>

      <v-col cols="12" md="6" lg="5" offset-lg="1" class="">

        <v-card>
          <v-card-title class="primary white--text py-2 text-subtitle-2">Current Job Status</v-card-title>
          <v-card-text class="pa-4">
            <v-simple-table dense>
              <tbody>
                <tr v-for="(s,k) in status" :key="k">
                  <td><span class="font-weight-medium">{{ s.name }}</span></td>
                  <td>
                    <div class="d-flex align-center" style="height:20px;">
                      <div style="width:50px" class="flex-shrink-0 text-right">
                      {{ s.count }}
                    </div>
                    <v-progress-linear class="ml-2 align-center"
                      :value="s.count / status_max_count * 100"
                      xheight="6"
                      rounded
                      background-color="transparent"
                    />
                    </div>
                    
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>

      </v-col>


      <v-col cols="12" md="6" lg="5" class="">

<v-card>
  <v-card-title class="primary white--text py-2 text-subtitle-2">Current Manufacturing Step</v-card-title>
  <v-card-text class="pa-4">

    <v-chip
      v-for="qms_type in qms_present_types" :key="qms_type"
      small
      class="mr-1 mb-1"
      @click="qms_toggle_type(qms_type, false)"
      @dblclick="qms_toggle_type(qms_type, true)"
      @contextmenu.prevent="qms_toggle_type(qms_type, true)"
      :outlined="!qms_types_active.includes(qms_type)"
      color="primary"
    >
       {{ qms.job_types[qms_type] }}
    </v-chip>

    <v-simple-table dense v-if="qms">
      <tbody>
        <tr v-for="(counts, step_key) in qms.counts" :key="step_key">
          <td><span class="font-weight-medium">{{ qms.qms_steps[step_key] }}</span></td>
          <td>

            <div class="d-flex align-center" style="height:20px;">
              <div style="width:50px" class="flex-shrink-0 text-right">
              {{ qms_step_counts_active[step_key] }}
            </div>

              <v-progress-linear class="ml-2 align-center"
                :value="qms_max_count == 0 ? 0 : (qms_step_counts_active[step_key] / qms_max_count * 100)"
                xheight="6"
                rounded2
                background-color="transparent"
              />
              </div>
          </td>
          
        </tr>
      </tbody>
    </v-simple-table>
  </v-card-text>
</v-card>

</v-col>

    </v-row>

  </v-container>
</template>

<style>
svg g text{
  fill: black;
  font-family: "Roboto", sans-serif;
  font-size: 0.4rem;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'Welcome',

  components: {
  },

  data: () => ({
    incoming: [],
    due: [],
    status: [],
    qms: null,
    qms_types_active: [],
  }),
  computed: {
    ...mapGetters('Auth', ['LOGGED_IN']),

    due_labels(){
      return this.due.map((count, index) => {
        let day = moment().startOf('day').add(index -1, 'days').format('ddd');
        if(index == 0)
          day = 'Overdue'
        return `${day} (${count})`;
      });
    },

    incoming_labels(){
      return this.incoming.map((count, index) => {
        return `${moment().startOf('day').subtract((this.incoming.length-1) - index, 'days').format('ddd')} (${count})`;
      });
    },


    status_max_count(){
      return Math.max(...this.status.map(status => status.count));
    },

    qms_max_count(){
      return Math.max(...Object.values(this.qms_step_counts_active));
    },

    qms_present_types(){
      let types_present = [];
      // get types that are present in the counts
      Object.keys(this.qms?.counts ?? {}).forEach(step => {
        types_present.push(...Object.keys(this.qms.counts[step]));
      });
      // order them
      return Object.keys(this.qms?.job_types ?? {}).filter(t => types_present.includes(t));
    },

    qms_step_counts_active(){
      let counts = {};
      Object.keys(this.qms?.counts ?? {}).forEach(step => {
        counts[step] = 0;
        Object.keys(this.qms.counts[step]).forEach(type => {
          if(this.qms_types_active.includes(type))
          counts[step] += this.qms.counts[step][type];
        });
      });
      return counts;
    }

  },
  methods:{
    ...mapActions('UI', ['SHOW_NAVBARS', 'NOTIFY', 'SET_PAGE_TITLE', 'CONFIRM']),
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('API', ['API']),


    async refresh(){
      this.incoming = await this.API({ method: 'GET', endpoint: 'labhome/stats_incoming' });
      this.due = await this.API({ method: 'GET', endpoint: 'labhome/stats_due' });

      this.API({ method: 'GET', endpoint: 'labhome/stats_status' })
      .then(status => {
        this.status = status.map(s => ({...s, count: 0 })); // initially load all counts as 0
        setTimeout(() => { this.status = status;}, 200) // then use actual data, allows lines to animate
      })

      this.API({ method: 'GET', endpoint: 'labhome/stats_qms' })
      .then(qms => {
        this.qms = qms;
        // animate by selecting shortly after
        this.$nextTick(() => {
          if(this.qms_types_active.length == 0)
            this.qms_types_active.push(...this.qms_present_types);
        });
      })
    },

    qms_toggle_type(qms_type, doubleClick){
      if(doubleClick){
        this.qms_types_active = [qms_type];
        return;
      }
      if(this.qms_types_active.includes(qms_type))
        this.qms_types_active = this.qms_types_active.filter(t => t != qms_type);
      else
        this.qms_types_active.push(qms_type);
    }

  },
  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS();
      this.SET_PAGE_TITLE('Home');
      this.refresh();
    });
  }
};
</script>
7